import React, { Component } from "react";
import { Grid, Row, Col, Button, Modal, FormGroup, FormControl } from "react-bootstrap";
import Card from "../../../components/Card/Card.jsx";
import { getInvoices, sendLoggedGETResponse, sendLoggedPUTAsync, sendLoggedPOSTAsync, API_VERSION_1 } from "../../../helpers/apiHelper";
import ReactTable from "react-table";
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import { Trans } from "react-i18next";
import i18n from '../../../i18n';
import { Link } from "react-router-dom";
import "../DriversCashout/PendingCashout.scss"
import { INVOICE_TYPES } from "../../../const/Invoices.js";
import { utcStringToLocalDateString } from "../../../helpers/dateHelper.js";
import { CurrencyFormatter } from "../../../helpers/CurrencyFormatter";
import moment from "moment";

class Invoices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            selectedInvoices: [],
            csvData: [],
            error: '',
            alert: null,
            focusedInput: null,
            showPaymentModal: false,
            selectedInvoice: null,
            paymentAmount: '',
        };
    }

    componentDidMount = () => {
        this.fetchPendingInvoices();
    }

    fetchPendingInvoices = async () => {
        await getInvoices().then(invoices => {
            invoices.sort((a, b) => {
                if ((a.paid === false && b.paid === false) || (a.paid === true && b.paid === true)) return new Date(a.from_date_inclusive) - new Date(b.from_date_inclusive)
                else if (a.paid == true && b.paid === false) return 1
                else return -1
            })
            this.setState({ invoices });
        }).catch(error => {
            console.error(error)
            this.errorAlert({ message: "ERROR_ALERT" });
        });
    }

    errorAlert = (error) => {
        const { title, message } = error;
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title={i18n.t(title || "ERROR")}
                    onConfirm={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    <p><Trans>{i18n.t(message)}</Trans></p>
                </SweetAlert>
            )
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    handlePaymentModalShow = (invoice) => {
        this.setState({
            showPaymentModal: true,
            selectedInvoice: invoice,
            paymentAmount: ''
        });
    }

    handlePaymentModalClose = () => {
        this.setState({
            showPaymentModal: false,
            selectedInvoice: null,
            paymentAmount: ''
        });
    }

    handlePaymentAmountChange = (e) => {
        // Only allow numeric input
        const value = e.target.value.replace(/\D/g, '');
        this.setState({ paymentAmount: value });
    }

    handlePaymentSubmit = async () => {
        const { selectedInvoice, paymentAmount } = this.state;
        
        try {
            await sendLoggedPOSTAsync(
                `invoices/${selectedInvoice.invoice_id}/transaction`,
                { 
                    amount: parseInt(paymentAmount),
                    zone_id: this.props.zone_id 
                },
                API_VERSION_1
            );
            
            this.handlePaymentModalClose();
            await this.fetchPendingInvoices(); // Refresh the list
            
        } catch (error) {
            console.error(error);
            this.errorAlert({ message: "PAYMENT_ERROR" });
        }
    }

    togglePaid = async (invoice) => {
        await sendLoggedPUTAsync(`invoices/${invoice.invoice_id}`, { zone_id: this.props.zone_id, paid: !invoice.paid }, API_VERSION_1)
    }

    togglePaidConfirmationAlert = (invoice) => {
        if (!invoice.paid) {
            this.handlePaymentModalShow(invoice);
            return;
        }

        // this.setState({
        //     alert: (
        //         <SweetAlert
        //             warning
        //             style={{ display: "block", marginTop: "-100px" }}
        //             title={<Trans>Mark unpaid</Trans>}
        //             onConfirm={async () => {
        //                 await this.togglePaid(invoice);
        //                 window.location.reload();
        //             }}
        //             confirmBtnBsStyle="success"
        //             cancelBtnText={i18n.t("CANCEL")}
        //             cancelBtnBsStyle="danger"
        //             onCancel={() => this.hideAlert()}
        //             showCancel
        //         >
        //             <p>Invoice id: {invoice.invoice_id}</p>
        //             <p><Trans>ARE_YOU_SURE</Trans></p>
        //         </SweetAlert>
        //     )
        // });
    }

    download = (endpoint, filename) => {
        sendLoggedGETResponse("api/v1/ana/" + endpoint, "", false, { params: { zone_id: this.props.zone_id }, responseType: "blob" })
            .then(response => new Blob([response.data], { type: response.headers["Content-Type"] }))
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    render() {
        const { invoices, showPaymentModal, selectedInvoice, paymentAmount } = this.state;

        return (
            <div className="content">
                {this.state.alert}
                <Grid fluid>
                    <br />
                    <Row>
                        <Col md={12}>
                            <Card
                                plain
                                ctTableFullWidth
                                ctTableResponsive
                                defaultPageSize={15}
                                showPaginationTop
                                showPaginationBottom={false}
                                className="-highlight"
                                content={
                                    <ReactTable
                                        className="-payment-table"
                                        data={invoices}
                                        filterable
                                        columns={[
                                            {
                                                Header: <Trans>Invoice Number</Trans>,
                                                id: "invoice_number",
                                                accessor: (invoice) => invoice.invoice_number,
                                                filterable: true
                                            },
                                            {
                                                Header: <Trans>BUSINESS_NAME</Trans>,
                                                id: "rider",
                                                accessor: (invoice) => {
                                                    return <Link
                                                        style={{ color: "#70CEEC", cursor: "pointer" }}
                                                    >
                                                        {invoice.recipient}
                                                    </Link>
                                                },
                                                filterable: true
                                            },
                                            {
                                                Header: <Trans>Paid</Trans>,
                                                id: "paid",
                                                accessor: (invoice) => {
                                                    let checkbox = <input type="checkbox"
                                                        checked={invoice.paid}
                                                        disabled={true}>
                                                    </input>
                                                    return checkbox;
                                                },
                                                sortable: true,
                                                filterable: false
                                            },
                                            {
                                                Header: <Trans>PAID_DATETIME</Trans>,
                                                id: "paid_date",
                                                accessor: (invoice) => {
                                                    return invoice.paid_date !== null ? utcStringToLocalDateString(invoice.paid_date) : null;
                                                },
                                                filterable: true
                                            },
                                            {
                                                Header: <Trans>Payment Type</Trans>,
                                                id: "invoice_type",
                                                accessor: (invoice) => {
                                                    return INVOICE_TYPES[invoice.invoice_type];
                                                },
                                                filterable: true
                                            },
                                            {
                                                Header: <Trans>Total</Trans>,
                                                id: "total_charge",
                                                accessor: (invoice) => {
                                                    return CurrencyFormatter.formatAmount(invoice.total_charge_with_tax_with_tip_with_refund, invoice.currency);
                                                },
                                                filterable: false
                                            },
                                            {
                                                Header: <Trans>Total CAD</Trans>,
                                                id: "total_charge_cad",
                                                accessor: (invoice) => {
                                                    return CurrencyFormatter.convertToCAD(invoice.total_charge_with_tax_with_tip_with_refund, invoice.currency, invoice.currency_exchange_rate);
                                                },
                                                filterable: false
                                            },
                                            {
                                                Header: <Trans>From_invoice</Trans>,
                                                id: "from_date_inclusive",
                                                accessor: (invoice) => {
                                                    return moment.utc(invoice.from_date_inclusive).format('YYYY-MM-DD');
                                                },
                                                filterable: true
                                            },
                                            {
                                                Header: <Trans>To_invoice</Trans>,
                                                id: "to_date_inclusive",
                                                accessor: (invoice) => {
                                                    return moment.utc(invoice.to_date_inclusive).format('YYYY-MM-DD');
                                                },
                                                filterable: true
                                            },
                                            {
                                                Header: <Trans>Files</Trans>,
                                                id: "select",
                                                accessor: (invoice) => {
                                                    return <span>
                                                        <a style={{ cursor: "pointer" }} onClick={() => { this.download(`invoices/${invoice.invoice_id}/csv`, `invoice-${invoice.invoice_number}.csv`) }}>csv </a>
                                                        <a style={{ cursor: "pointer" }} onClick={() => { this.download(`invoices/${invoice.invoice_id}/pdf`, `invoice-${invoice.invoice_number}.pdf`) }}>pdf </a>
                                                        <a style={{ cursor: "pointer" }} onClick={() => { this.download(`invoices/${invoice.invoice_id}/excel`, `invoice-${invoice.invoice_number}.xlsx`) }}>excel</a>
                                                    </span>;
                                                },
                                                filterable: false
                                            },
                                            {
                                                Header: <Trans>actions</Trans>,
                                                id: "action",
                                                accessor: (invoice) => {
                                                    return invoice.paid ? null : (<div className="actions-right">
                                                        <Button
                                                            onClick={() => this.togglePaidConfirmationAlert(invoice)}
                                                            bsStyle="success"
                                                            bsSize="xsmall">
                                                            <Trans>Mark paid</Trans>
                                                        </Button>
                                                    </div>)
                                                },
                                                sortable: false,
                                                filterable: false
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Col>
                    </Row>
                </Grid>

                <Modal show={showPaymentModal} onHide={this.handlePaymentModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title><Trans>Enter Payment Amount</Trans></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup>
                            <FormControl
                                type="text"
                                value={paymentAmount}
                                placeholder="Enter amount in smallest currency unit"
                                onChange={this.handlePaymentAmountChange}
                            />
                            {selectedInvoice && (
                                <p className="help-block">
                                    <Trans>Expected invoice total</Trans>: {selectedInvoice.total_charge_with_tax_with_tip_with_refund} ({selectedInvoice.currency})
                                </p>
                            )}
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handlePaymentModalClose}><Trans>Cancel</Trans></Button>
                        <Button 
                            bsStyle="primary" 
                            onClick={this.handlePaymentSubmit}
                            disabled={!paymentAmount}
                        >
                            <Trans>Submit Payment</Trans>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.userData.isAuthenticated,
        pubKey: state.userData.publicKey,
        privKey: state.userData.privateKey,
        community: state.userData.communityAccount,
        cityName: state.userData.cityName,
        zone_id: state.zones.selected.zone_id
    };
};

export default connect(mapStateToProps)(Invoices);